import { Api } from '../../types/api'
import ConstraintViolationList = Api.ConstraintViolationList
import ErrorResponse = Api.ErrorResponse

export interface FetcherInput<R> {
    url: string
    params?: R
    accessToken?: string
    method?: string
    contentType?: string
    hostname?: string
}

export const fetcher = async <Q, S>(config: FetcherInput<Q>): Promise<S | null> => {
    const headers: HeadersInit = new Headers()

    if (config.contentType) {
        headers.set('Content-Type', config.contentType)
    } else {
        headers.set('Content-Type', 'application/json')
    }

    if (config.hostname) {
        headers.set('X-Dann-Hostname', config.hostname)
    }

    if (config.accessToken) {
        headers.append('Authorization', `Bearer ${config.accessToken}`)
    }

    try {
        const apiUrl = process.env.NEXT_SERVER_API_URL
            ? process.env.NEXT_SERVER_API_URL
            : process.env.NEXT_PUBLIC_API_URL

        const response = await fetch(
            `${apiUrl}${config.url.replace('/api', '')}`,
            config.params
                ? {
                      method: config.method ? config.method : 'POST',
                      headers,
                      body: JSON.stringify(config.params),
                  }
                : {
                      method: config.method ? config.method : 'GET',
                      headers,
                  }
        )
        console.log('RESPONSE', response)
        if (response.ok) {
            if (config.contentType === 'text/csv') {
                return (await response.text().catch(() => ({}))) as Promise<S>
            }
            return (await response.json().catch(() => ({}))) as Promise<S>
        } else {
            return (await response.json().catch(() => ({}))) as Promise<S>

            //the below would be better since it would end up in the catch and would result in better error handling, but all fetcher calls would need to be checked
            // return Promise.reject(await response.json().catch(() => ({})))
        }
        return Promise.reject(response.status)
    } catch (e) {
        console.log('fetch error', e)
    }
    return null
}

export const isConstraintViolationList = (obj: unknown): obj is ConstraintViolationList => {
    return (obj as ConstraintViolationList).violations !== undefined
}

export const isErrorResponse = (obj: unknown): obj is ErrorResponse => {
    return (obj as ErrorResponse)['@type'] === 'hydra:Error'
}
