import React, { useEffect } from 'react'
import { appWithTranslation } from 'next-i18next'
import App, { AppContext, AppInitialProps, AppProps } from 'next/app'
import './../assets/css/App.scss'
import './../assets/css/Tailwind.css'
import './../assets/css/nprogress.css'
import { getSession, SessionProvider } from 'next-auth/react'
import { ToastContainer } from 'react-toastify'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { fetcher } from '../api/io'
import { Api } from '../../types/api'
import User = Api.User
import { AppProvider } from '../state/AppContext'
import NProgress from 'nprogress'
import { Router, useRouter } from 'next/router'
import { Session } from 'next-auth'
import PiwikProProvider from '@piwikpro/next-piwik-pro'

if (typeof window !== 'undefined') {
    injectStyle()
}

type AppOwnProps = {
    user: User | null
    session: Session | null
}

const DannApp = ({ Component, pageProps, user, session }: AppProps & AppOwnProps) => {
    const router = useRouter()

    NProgress.configure({ showSpinner: false })

    useEffect(() => {
        const handleRouteStart = () => NProgress.start()
        const handleRouteDone = () => {
            NProgress.done()
        }

        Router.events.on('routeChangeStart', handleRouteStart)
        Router.events.on('routeChangeComplete', handleRouteDone)
        Router.events.on('routeChangeError', handleRouteDone)

        return () => {
            // Make sure to remove the event handler on unmount!
            Router.events.off('routeChangeStart', handleRouteStart)
            Router.events.off('routeChangeComplete', handleRouteDone)
            Router.events.off('routeChangeError', handleRouteDone)
        }
    }, [])

    return (
        <SessionProvider session={session} refetchInterval={5 * 60}>
            <AppProvider user={user}>
                <PiwikProProvider
                    containerUrl={process.env.NEXT_PUBLIC_CONTAINER_URL}
                    containerId={process.env.NEXT_PUBLIC_CONTAINER_ID}
                >
                    <Component {...pageProps} key={router.asPath} />
                </PiwikProProvider>
                <ToastContainer
                    theme="colored"
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </AppProvider>
        </SessionProvider>
    )
}

DannApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
    const ctx = await App.getInitialProps(context)
    const session = await getSession({ req: context.ctx.req })

    let user: User | null = null

    if (session?.user) {
        try {
            user = await fetcher<null, User>({
                url: '/users/' + session?.user.id,
                accessToken: session?.user.accessToken,
            })
        } catch (e) {
            console.log('error', e)
        }
    }

    return {
        ...ctx,
        user: user,
        session,
    }
}

// @ts-ignore
export default appWithTranslation(DannApp)
