import { Api } from '../../types/api'
import User = Api.User
import React, { useEffect, useReducer, useState } from 'react'
import { USER_ACTION_CHANGE, UserActionTypes, userReducer } from './userReducer'
import { useSession } from 'next-auth/react'
import { Session } from 'next-auth'
import { fetcher } from '../api/io'
import { useRouter } from 'next/router'
import { routes } from '../utils/routes'
import { isAllowed } from '../utils/Helpers'
import FourOhThree from '../pages/403'

export type ActionTypes = UserActionTypes

export interface UserState {
    user: User | null
}
export interface AppState {
    userState: UserState
}
const defaultState: AppState = {
    userState: {
        user: null,
    },
}

export interface AppContext {
    state: AppState
    dispatch: React.Dispatch<ActionTypes>
}

const appContext = React.createContext<AppContext>({
    state: defaultState,
    dispatch: () => null,
})

const mainReducer = (state: AppState, action: ActionTypes): AppState => ({
    // status: statusReducer(state.status, action as StatusActionTypes),
    userState: userReducer(state.userState, action),
})

interface AppProviderProps {
    user: User | null
    children: React.ReactNode
}

const AppProvider = (props: AppProviderProps) => {
    const { data: session, status } = useSession()
    const router = useRouter()
    const [hasRoles, setHasRoles] = useState(false)

    useEffect(() => {
        // console.log('PATHNAME:', router.pathname)

        const key = Object.keys(routes).find((key) => routes[key]?.path === router.pathname)
        setHasRoles(isAllowed(key ? routes[key].roles : [], props.user ? props.user.roles : [], 'some'))
    }, [router.pathname, props.user])

    useEffect(() => {
        const fetchUser = async (session: Session) => {
            const user = await fetcher<null, User>({
                url: '/users/' + session.user.id,
                accessToken: session.user.accessToken,
            })
            dispatch({
                type: USER_ACTION_CHANGE,
                user: user,
            })
            return user
        }

        if (status === 'authenticated' && props.user === null) {
            fetchUser(session).catch(console.error)
        }
    }, [status, session])

    const initialState = { ...defaultState, userState: { user: props.user } }

    const [state, dispatch] = useReducer(mainReducer, initialState)
    if (!hasRoles) return <FourOhThree />
    return <appContext.Provider value={{ state, dispatch }}>{props.children}</appContext.Provider>
}

export { AppProvider, appContext }
