import { Reducer } from 'react'
import { Api } from '../../types/api'
import User = Api.User
import { UserState } from './AppContext'

export const USER_ACTION_CHANGE = 'USER_ACTION_CHANGE'

interface UserActionChange {
    type: typeof USER_ACTION_CHANGE
    user: User | null
}

export type UserActionTypes = UserActionChange

export const userReducer: Reducer<UserState, UserActionTypes> = (userState: UserState, action: UserActionTypes) => {
    switch (action.type) {
        case USER_ACTION_CHANGE:
            userState.user = action.user
            return userState
        default:
            return userState
    }
}
