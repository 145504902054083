import React, { ReactNode } from 'react'

interface DefaultLayoutProps {
    children: ReactNode
}

const DefaultLayout: React.FC<DefaultLayoutProps> = (props) => {
    return <div className="min-h-full">{props.children}</div>
}

export default DefaultLayout
