import React, { useEffect, useState } from 'react'

export interface LogoProps {
    className?: string
    stamp?: boolean
    type?: 'dark' | 'light'
}

const Logo: React.FC<LogoProps> = ({ className = '' }) => {
    const [subdomain, setSubDomain] = useState<string>('default')

    useEffect(() => {
        const hostname = window.location.hostname
        const subdomain = hostname.split('.')[0]
        if (subdomain === 'dann' || subdomain === 'localhost' || subdomain === 'www' || subdomain === 'dann-frontend') {
            setSubDomain('default')
        } else {
            setSubDomain(subdomain)
        }
    }, [])

    return (
        <figure>
            <img
                className={`${className}`}
                src={`/assets/img/${subdomain}-logo.svg`}
                alt="De Digitale Academie Noord-Nederland"
                title="De Digitale Academie Noord-Nederland"
            />
        </figure>
    )
}

export default Logo
