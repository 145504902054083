export const isVideoFile = (name: string) => {
    return name.match('.(avi|AVI|wmv|WMV|flv|FLV|mpg|MPG|mp4|MP4|mov|MOV)')
}

export const isAllowed = (matchRoles: string[], userRoles: string[], fulfill = 'all'): boolean => {
    if (matchRoles.length === 0) return true

    let allowed
    switch (fulfill) {
        case 'some':
            allowed = matchRoles.some((role) => userRoles.includes(role))
            break
        case 'all':
        default:
            allowed = matchRoles.every((role) => userRoles.includes(role))
            break
    }
    return allowed
}

export const decode = (str: string): string => Buffer.from(str, 'base64').toString('binary')
